import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import './style.css';
import certificado from "../../img/icon_certificado_com_risco.svg";
import quadrado from "../../img/quadrados_conteudo.svg";
class Conteudo extends Component {
    render() {
        return (
            <div className="App-conteudo">
                <div className="container-conteudo">
                    <Grid container spacing={3}>
                        <Grid container direction="row"
                            justify="center"
                            alignItems="center" item xs={12}
                            className="cont"
                            ><h1 className="title-conteudo">Conteúdo a ser abordado:</h1>
                            </Grid>
                       
                        <Grid container direction="row"
                           justify="flex-end"
                            alignItems="center" item xs={9}>
                           
                            <Grid container alignItems="center" justify="flex-end" className="ajust-responsivo">
                            <div className="ajust-tilte-certificado" style={{ padding: 20}}>
                            <h2 >Certificado do Curso pela Flex</h2>
                            </div>
                            <img alt="certificado" src={certificado} className="img-certificado" />
                            <span className="vl" />
                            <Grid className="informacoes-landing">
                                <ul className="info-1">
                                    <h3 style={{paddingBottom: 40}}>Landing Page</h3>
                                    <div style={{marginTop: -50}}>
                                    <li>Editor de texto;</li>
                                    <li>HTML;</li>
                                    <li>CSS;</li>
                                    <li>javaScript;</li>
                                    ... e outros
                                    </div>
                                </ul>
                                <ul className="info-2">
                                <h3 style={{paddingBottom: 40}}>Integração e API</h3>
                                <div style={{marginTop: -50}}>
                                    <li>Arquitetura Cliente e Servidor</li>
                                    <li>Node js</li>
                                    <li>Rest</li>
                                    </div>
                                </ul>
                                <ul className="info-3">
                                <h3 style={{paddingBottom: 40}}>Banco de Dados</h3>
                                <div style={{marginTop: -50}}>
                                    <li>MySQL</li>
                                    <li>Mongo</li>
                                    <li>Infraestrutura</li>
                                    </div>
                                </ul>
                            </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}  className="resp-none">
                           
                        </Grid>

                    </Grid>
                </div>

            </div>
        );

    }
}

export default Conteudo