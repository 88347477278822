import React, { Component } from "react";
import "./style.css";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import saveForm from '../../service/inscribe';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputMask from 'react-input-mask';


class Inscricao extends Component {
  state = {
    user: {
      nome: '',
      email: '',
      telefone: '',
      nameError: '',
      emailError: '',
    }
  }


  handleChange = event => {
    const isCheckbox = event.target.type === "checkbox";
    this.setState({
     
      [event.target.name] : isCheckbox
      ? event.target.checked
      : event.target.value
    });
  };

  validate = () => {
    let nomeError = "";
    let emailError = "";
    // let passwordError = "";

    if (!this.state.user.nome) {
      nomeError = "nome cannot be blank";
    }

    if (!this.state.user.email.includes("@")) {
      emailError = "invalid email";
    }

    if (!this.state.user.telefone) {
      emailError = "invalid telefone";
    }

    if (emailError || nomeError) {
      this.setState({ emailError, nomeError });
      return false;
    }

    return true;
  };

  handleSubmit = event => {
    event.preventDefault();
   
  };

  sendForm = () => {
    const { user } = this.state;
    const isValid = this.validate();
    
    if (isValid) {
      saveForm(user)
   
    this.setState({
      user: {
        nome: '',
        email: '',
        telefone: '',
      }
    })

    document.getElementsByClassName("manipulacao-input")[0].style.display = "none";
    document.getElementsByClassName("pronto-show")[0].style.display = "block";
    }
    document.getElementsByClassName("error-input")[0].style.display = "block";
  }

  onChange = (e) => {
    window.gtag('event', 'inscrição', {
      'event_category': 'clique',
      'event_label': 'enviar'
    });

      const { name, value } = e.target;
      let { user } = this.state;
      user[name] = value;
      this.setState({ user });
      console.log(user);
    
    
   
  }
  
  disabled = (e) => {
    const { name, value } = e.target;
    let { user } = this.state;
    user[name] = value;

  }



  render() {
    const { user } = this.state;
    return (
      <div className="App-inscricao">
        <div className="container-inscricao">
          <Grid container >
            <div  className="resp-flex">
          <Grid
              container
              justify="flex-start"
              alignItems="baseline"
              item
              xs={11}
             
            >

              <ul className="info-inscricao">
                <h3 className="title-inscricao"  style={{marginTop: -45}}>Como faço para participar?</h3>
                <div>
                  <li>
                    Teste de nivelamento para garantir a qualidade das aulas
                    ministradas;
                  </li>
                  <li style={{ paddingTop: 10, paddingBottom: 10 }}>
                    Deve estar cursando a universidade;
                  </li>
                  <li style={{ paddingBottom: 10 }}>
                    Deve ter um notebook para participar das aulas.
                  </li>
                </div>
              </ul>
            </Grid>
            </div>

            <Grid container justify="center" item xs={7}>
              <div className="barra-terminal-position resp-flex ">
            <span className="barra-terminal">Zero2Dev</span>
            </div>
              <div
                className="teste terminal-position"
                style={{ backgroudColor: "white" }}
              >
                
              
                {/* <form onSubmit={this.handleSubmit} className="manipulacao-input">
                <span className="error-input">{"Preencha os campos corretamente!"}</span>
                  <p className="color-green"><p className="color-green">{"{"}</p>
                    {'"Nome_completo"'} :
                  <Input
                      className="inputMatrix"
                      name="nome"
                      style={{ padding: '2px' }}
                      margin="normal"
                      value={user.nome}
                      onChange={this.onChange}
                    /></p>

                  <p className="color-green" style={{ marginTop: "-4px" }}>{'"Email"'}  :
                  <Input
                      className="inputMatrix"
                      name="email"
                      style={{ padding: '2px' }}
                      value={user.email}
                      onChange={this.onChange}
                    />
                  </p>
                  <p className="color-green" style={{ marginTop: "-3px" }}>{'"Telefone"'} :

                  <InputMask
                      mask="(99) 9 9999-9999"
                      className="inputMatrix"
                      name="telefone"
                      style={{ padding: '10px' }}
                      maskChar={null}
                      value={user.telefone}
                      onChange={this.onChange}
                    />
                    <p>{"}"}</p>

                  </p>

                  <Button type="submit" onClick={this.sendForm} variant="contained" className="btn-enviar">
                    Enviar
                  </Button>

                </form> */}

                <div className="pronto-show">
                  <Typography>As inscrições estão encerradas!</Typography>
                  <Typography>Fique de olho e não perca as inscrições para a próxima turma!</Typography>
                  <Typography>Até logo!</Typography>
                </div>
              </div>
            </Grid>

            
            <Grid
              className="resp-none"
              container
              justify="flex-start"
              alignItems="baseline"
              item
              xs={4}
            >

              <ul className="info-inscricao resp-none">
                <h3 className="title-inscricao">Como faço para participar?</h3>
                <div>
                  <li>
                    Teste de nivelamento para garantir a qualidade das aulas
                    ministradas;
                  </li>
                  <li style={{ paddingTop: 10, paddingBottom: 10 }}>
                    Deve estar cursando a universidade;
                  </li>
                  <li style={{ paddingBottom: 10 }}>
                    Deve ter um notebook para participar das aulas.
                  </li>
                </div>
              </ul>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default (Inscricao);


{/* <div className="ajust-button-quero resp-none">
<Button onClick={() => this.viewForm()} variant="contained" className="btn-enviar">
  Quero me inscrever_
</Button>
</div>      */}