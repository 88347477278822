import React from 'react';
import Header from './component/header'
import Cursar from './component/cursar'
import Oportunit from './component/oportunidade'
import Conteudo from './component/conteudo'
import Inscricao from './component/inscricao'
import Footer from './component/footer'




function App() {
  return (
    <div>
      <Header />
      <Cursar />
      <Oportunit />
      <Conteudo />
      <Inscricao />
      <Footer />

    </div>
  );
}

export default App;
