import React, { Component } from 'react';
import './style.css';

class Footer extends Component {
    render() {
        return (
            <div className="App-footer">
               
                    <p className="text-footer">Zero to Dev - Flex 2019</p>
            </div>
        );
    }
}

export default Footer;