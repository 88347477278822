const axios = require('axios');

function saveForm(user) {
    // console.log(user);
    // debugger;
    // fetch('https://us-central1-xlab-projects.cloudfunctions.net/zero2dev-api', {
    //    method: 'post',
    //    headers: {'Content-Type':'application/json'},
    //    body: JSON.stringify(user),
    //    mode: 'no-cors' 
    //  })
    axios.post('https://us-central1-xlab-projects.cloudfunctions.net/zero2dev', user).then(console.log);
}

export default saveForm;
