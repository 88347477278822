import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import './style.css';

class Oportunit extends Component {
    render() {
        return (
            <div className="App-oportunit">
                 <div className="container-oportunit">
                     <Grid container spacing={3}>
                        <Grid item xs={2} className="setas" style={{color: "#2E54FD"}}>{"<!--"}</Grid>
                        <Grid item xs={8} className="paragrafo">
                        <p className="paragrafo">Oportunidade de <span className="border">desenvolver</span> um projeto com os conteúdos aprendidos em sala</p>
                        <p className="paragrafo"><span className="border">Tutores</span> disponíveis para sanar dúvidas</p>
                        <p className="paragrafo">Programa intensivo: capacitação em <span className="border">4 meses</span></p>
                        </Grid>
                        <Grid container alignItems="flex-end" item xs={2} className="setas"  style={{color: "#232B99"}}>{"-->"}</Grid>

                     </Grid>
                 </div>

            </div>
        );
    
    }
    }

    export default Oportunit