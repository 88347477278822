import React, { Component } from 'react';
import './style.css';
import Grid from '@material-ui/core/Grid';
import ilustra from '../../img/ilustra_pq_quad.svg';
import quadradoVerde from '../../img/quadrado_verde.svg';
import dias from '../../img/icon_dias.png';
import hora from '../../img/icon_hora.png';
import inicio from '../../img/icon_inicio.png';
import local from '../../img/icon_local.png';

import bullet from '../../img/bullet_icon.svg';



import Typography from '@material-ui/core/Typography'

class Heade extends Component {
    render() {
        return (
            <div className="container">
                <div className="container-cursar">
                    <Grid container >

                        <Grid item xs={2} className="resp-none">
                            <img alt="quadradoVerde" src={quadradoVerde} className="quadrado-verde" />
                        </Grid>
                        <Grid item xs={6} >
                            <Typography variant="h5" className="color-txt roboto">Zero2Dev: Curso GRÁTIS de Programação Web para Iniciantes</Typography>

                            <Grid className="color-txt-cinza">
                                <p style={{ marginTop: 30, fontSize: '16px' }}>Temos plena certeza, e podemos comprovar: você pode aprender a programar e sair do
                                 zero de conhecimento na área para se tornar um verdadeiro DEV, ou desenvolvedor (do inglês, Developer), pronto para trabalhar em uma das inúmeras vagas disponíveis.</p>

                                <p className="pad-tb" style={{ fontSize: '16px' }}>  O projeto oferece 4 meses de curso gratuito para você aprender,
                                 adquirir experiência e se preparar para fazer parte do mercado que mais cresce em SC: o da tecnologia.
                                </p>

                                <p variant="h5" className="sub-title color-txt"> Você só precisa ser <b style={{ backgroundColor: '#2E54FD', color: 'white' }}>universitário,</b> cursando em qualquer área,
                                e apresentar o seu atestado de matrícula na inscrição. Veja os detalhes abaixo:</p>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} className="resp-none">
                            <img alt="ilustra" src={ilustra} className="img-ilustra" />
                        </Grid>


                        <Grid item xs={8} />


                        <Grid item xs={2} />

                        <Grid container direction="row" alignItems="center" className="space-itens" item xs={12} style={{marginTop: 50}}>
                            <Grid container direction="row" justify="flex-end" alignItems="center" className="ajust-grid-6" item xs={6}>

                                <Grid container justify="flex-start" className="grid-align-column" item xs={5}>
                                    <img alt="hora" src={hora} className="img-icon-2" />
                                    <Grid container item justify="center" alignItems="center" >
                                        <p className="info-icon ml-info">
                                            78 horas de aula</p>
                                    </Grid>
                                </Grid>

                                <Grid item xs={1} container justify="center" className="resp-none" alignItems="flex-start">
                                    <img alt="bullet" src={bullet} className="ponto resp-none" />
                                </Grid>

                                <Grid container justify="flex-start" item xs={5} className="grid-align-column">
                                    <img alt="inicio" src={inicio} className="img-icon-i" />
                                    <Grid container item justify="center" alignItems="center">
                                        <p className="info-icon mt" >
                                            Início do Curso 06 de Agosto </p>
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid container direction="row" justify="flex-start" className="resp-none ajust-grid-6" alignItems="center" item xs={6}>
                                <Grid item xs={1} container justify="center" alignItems="flex-start">
                                    <img alt="bullet" src={bullet} className="ponto resp-none" />
                                </Grid>

                                <Grid container justify="flex-start" item xs={5} className="grid-align-column ml-div-dia">
                                    <img alt="dias" src={dias} className="img-icon" />

                                    <Grid container item justify="center" alignItems="center">
                                        <p className="info-icon mt ml-info-5" >
                                            Aulas terças e quintas das 18h15 às 21h30
                                </p>
                                    </Grid>
                                </Grid>


                                <Grid item xs={1} container className="resp-none" justify="center" alignItems="flex-start">
                                    <img alt="bullet" src={bullet} className="ponto resp-none" />
                                </Grid>

                                <Grid container justify="flex-start" item xs={5} className="grid-align-column">
                                    <img alt="local" src={local} className="img-icon-l" />
                                    <Grid container item justify="center" alignItems="center">
                                        <p className="info-icon" >
                                            Local: Acate Primavera Rodovia SC 401 </p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                </div>
            </div>
        );
    }
}

export default Heade;