import React, { Component } from 'react';
import './style.css';
import Grid from '@material-ui/core/Grid';
import zero from '../../img/logo_zero_2_dev.svg'
import mesa from '../../img/ilustra_topo.png'

import Typography from '@material-ui/core/Typography'

class Header extends Component {
    render() {
        return (
            <div className="App-header">
                <div className="container-header">
                    <Grid container spacing={3}>
                        <Grid container
                            direction="column"
                            justify="center"
                            alignItems="center" 
                            item xs={6}>

                            <img src={zero} className="img-zero" />
                                <Grid className="text-header">
                                <p style={{marginTop: '40px'}}>
                                Fato: não importa o seu curso, você pode aprender a programar e isso pode mudar sua vida.
                                </p>
                                </Grid>
                        </Grid>
                        <Grid item xs={6} className="grid-align-end-items">
                            <img src={mesa} className="img-mesa" />

                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default Header;